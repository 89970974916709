import React, { useEffect, useState } from 'react';

const ContentRenderer = ({ index, htmlContent, conversation_id }) => {
  const [imageUrls, setImageUrls] = useState([]);
  const [coversation, setConversation] = useState(null);

  // Reset images when conversation_id changes
  useEffect(() => {
    if (conversation_id?.id !== coversation) {
      setImageUrls([]); // Reset image list
      setConversation(conversation_id?.id);
    }
  }, [conversation_id?.id]); // Run only when conversation_id.id changes

  useEffect(() => {
    // Function to handle anchor tag clicks
    const handleAnchorClick = (event) => {
      event.preventDefault();
      const url = event.target.href;
      window.open(url, '_blank');
    };

    // Get all anchor tags within the rendered HTML
    const anchors = document.querySelectorAll('.markDownSetting a');
    anchors.forEach((anchor) => {
      anchor.addEventListener('click', handleAnchorClick);
    });

    // Search for all anchor tags with href attributes containing .png or .jpg
    const anchorTags = htmlContent.match(/<a[^>]+href="([^">]+)"/g);

    if (anchorTags) {
      const urls = anchorTags
        .map((tag) => {
          const match = tag.match(/href="([^">]+)"/);
          return match ? match[1] : null;
        })
        .filter(Boolean)
        .filter((url) => url.endsWith('.png') || url.endsWith('.jpg'));

      setImageUrls(urls);
    }

    // Cleanup event listeners on component unmount
    return () => {
      anchors.forEach((anchor) => {
        anchor.removeEventListener('click', handleAnchorClick);
      });
    };
  }, [htmlContent]); // Runs when htmlContent changes

  return (
    <div>
      {/* Render the content */}
      <span id={`id_${index}`} className="markDownSetting">
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </span>

      {/* Render images found in the anchor tags */}
      {imageUrls.length > 0 && (
        <div style={{ marginTop: '20px' }}>
          {imageUrls.map((url, idx) => (
            <img key={idx} src={url} alt={`Content Image ${idx}`} style={{ width: '200px', height: 'auto' }} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ContentRenderer;

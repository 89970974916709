import React, { useState } from "react";
import Icon from "components/Icon";

const Sidebar2 = ({ text, LoadTaskonchat, OpenAssistant, assistantData, changeassistant, asistantSelected, toggleSidebar }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const closeNav = () => {
    document.getElementById("searchsidebar").style.width = "0";
    document.getElementById("tasksidebar").style.width = "0";
    document.getElementById("assistantsidebar").style.width = "0";

  };

  const [isOpendrop, setIsOpendrop] = useState(false);
  const toggleDropdownd = () => setIsOpendrop(!isOpendrop);
  const [threeDotOption, setThreeDotOption] = useState(false)
  const [moreOption, setMoreOption] = useState(false)

  return (
    <div>
      <div id="assistantsidebar" className="v3_sec_sidebar">
        <span className="crossicon" style={{ margin: "0px 6px 0px 0px" }} onClick={closeNav}>
          ×
        </span>
        <div style={{ paddingLeft: "10px" }}>
          <h2 className="result_3">{assistantData?.length} AI Agents</h2>
          {assistantData?.map((item, index) => (

            <div key={index} className="box_1 fatAdded" onClick={() => changeassistant(item)}>
              <div style={{ marginBottom: "7px" }}>
                <b className="box_1_b">{item.assistant_name}</b>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="v3_sec_sidebar__openbtn" onClick={() => toggleSidebar('assistant')}>
        {text}
      </div>


    </div>
  );
};

export default Sidebar2;
